import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
/**
 * The user service.
 */
@Injectable()
export class AuthenticationService {

    /**
     * True if authenticated
     * @type
     */
    private _authenticated = false;


    hashCode(s) {
        return s.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0);
    }

    /**
     * Authenticate the user
     *
     * @param {string} email The user's email address
     * @param {string} password The user's password
     * @returns {Observable<User>} The authenticated user observable.
     */
    public authenticate(username: string, password: string): Observable<any> {

        if (this.hashCode(username) == "-325843908" && this.hashCode(password) == "1516358")
            return of(true);
        else
            return of(false);

    }

    errorHandler(error) {
        return throwError(new Error("Invalid email or password"));
    }

    /**
     * Determines if the user is authenticated
     * @returns {Observable<boolean>}
     */
    public authenticated(): Observable<boolean> {
        return of(this._authenticated);
    }

    /**
     * Returns the authenticated user
     * @returns {User}
     */
    // public authenticatedUser(): Observable<string> {
    //   // Normally you would do an HTTP request to determine if
    //   // the user has an existing auth session on the server
    //   // but, let's just return the mock user for this example.
    //   return of(MOCK_USER);
    // }

    /**
     * Create a new user
     * @returns {User}
     */
    // public create(user: Credentials): Observable<Credentials> {
    //   // Normally you would do an HTTP request to POST the user
    //   // details and then return the new user object
    //   // but, let's just return the new user for this example.
    //   this._authenticated = true;
    //   return of(user);
    // }

    /**
     * End session
     * @returns {Observable<boolean>}
     */
    public signout(): Observable<boolean> {
        // Normally you would do an HTTP request sign end the session
        // but, let's just return an observable of true.
        this._authenticated = false;
        return of(true);
    }
}
