// import @ngrx
import { Action } from "@ngrx/store";

export const AUTHENTICATE = "USER_AUTHENTICATE";
export const AUTHENTICATE_ERROR = "USERS_AUTHENTICATE_ERROR";
export const AUTHENTICATE_SUCCESS = "USERS_AUTHENTICATE_SUCCESS";
export const AUTHENTICATED = "USERS_AUTHENTICATED";
export const AUTHENTICATED_ERROR = "USERS_AUTHENTICATED_ERROR";
export const AUTHENTICATED_SUCCESS = "USERS_AUTHENTICATED_SUCCESS";
export const SIGN_OUT = "USERS_SIGN_OUT";
export const SIGN_OUT_ERROR = "USERS_SIGN_OUT_ERROR";
export const SIGN_OUT_SUCCESS = "USERS_SIGN_OUT_SUCCESS";
export const SIGN_UP = "USERS_SIGN_UP";
export const SIGN_UP_ERROR = "USERS_SIGN_UP_ERROR";
export const SIGN_UP_SUCCESS = "USERS_SIGN_UP_SUCCESS";

/**
 * Authenticate.
 * @class AuthenticateAction
 * @implements {Action}
 */
export class AuthenticateAction implements Action {
  readonly type = AUTHENTICATE;

  constructor(public payload: { email: string, password: string }) { }
}

/**
 * Checks if user is authenticated.
 * @class AuthenticatedAction
 * @implements {Action}
 */

export class AuthenticatedAction implements Action {
    readonly type = AUTHENTICATED;
  
    constructor(public payload?: any) { }
  }
  
/**
 * Authenticated check success.
 * @class AuthenticatedSuccessAction
 * @implements {Action}
 */
export class AuthenticatedSuccessAction implements Action {
  readonly type = AUTHENTICATED_SUCCESS;

  constructor(public payload: boolean) { }
}

/**
 * Authenticated check error.
 * @class AuthenticatedErrorAction
 * @implements {Action}
 */
export class AuthenticatedErrorAction implements Action {
  readonly type = AUTHENTICATED_ERROR;

  constructor(public payload?: any) { }
}

/**
 * Authentication error.
 * @class AuthenticationErrorAction
 * @implements {Action}
 */
export class AuthenticationErrorAction implements Action {
  readonly type = AUTHENTICATE_ERROR;

  constructor(public payload?: any) { }
}

/**
 * Authentication success.
 * @class AuthenticationSuccessAction
 * @implements {Action}
 */
export class AuthenticationSuccessAction implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(public payload: boolean) { }
}

/**
 * Sign out.
 * @class SignOutAction
 * @implements {Action}
 */
export class SignOutAction implements Action {
  readonly type = SIGN_OUT;
  constructor(public payload?: any) { }
}

/**
 * Sign out error.
 * @class SignOutErrorAction
 * @implements {Action}
 */
export class SignOutErrorAction implements Action {
  readonly type = SIGN_OUT_ERROR;
  constructor(public payload?: any) { }
}

/**
 * Sign out success.
 * @class SignOutSuccessAction
 * @implements {Action}
 */
export class SignOutSuccessAction implements Action {
  readonly type = SIGN_OUT_SUCCESS;
  constructor(public payload?: any) { }
}

/**
 * Sign up.
 * @class SignUpAction
 * @implements {Action}
 */
export class SignUpAction implements Action {
  readonly type = SIGN_UP;
  constructor(public payload: { token: string }) { }
}

/**
 * Sign up error.
 * @class SignUpErrorAction
 * @implements {Action}
 */
export class SignUpErrorAction implements Action {
  readonly type = SIGN_UP_ERROR;
  constructor(public payload?: any) { }
}

/**
 * Sign up success.
 * @class SignUpSuccessAction
 * @implements {Action}
 */
export class SignUpSuccessAction implements Action {
  readonly type = SIGN_UP_SUCCESS;
  constructor(public payload: { token: string }) { }
}


/**
 * Actions type.
 * @type {Actions}
 */
export type Actions
  =
  AuthenticateAction
  | AuthenticatedAction
  | AuthenticatedErrorAction
  | AuthenticatedSuccessAction
  | AuthenticationErrorAction
  | AuthenticationSuccessAction
  | SignUpAction
  | SignUpErrorAction
  | SignUpSuccessAction
  | SignOutAction
  | SignOutErrorAction
  | SignOutSuccessAction

