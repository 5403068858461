import { Component, OnInit } from '@angular/core';
import { SidenavItem } from '../sidenav/sidenav-item/sidenav-item.model';
import * as fromRoot from '../../reducers/index';
import * as fromSidenav from '../sidenav/shared/sidenav.action';
import { SetCurrentlyOpenByRouteAction } from '../sidenav/shared/sidenav.action';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SelectLayoutAction, SetCardElevationAction } from '../layout/shared/layout.action';

@Component({
  selector: 'elastic-route-handler',
  templateUrl: './route-handler.component.html',
  styleUrls: ['./route-handler.component.scss']
})
export class RouteHandlerComponent implements OnInit {

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // Set Sidenav Currently Open on Page load
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects));
      }
    });

    // You can use ?layout=beta to load the page with Layout Beta as default
    // Same with ?elevation=5 (anything from 0 to 24)
    this.route.queryParamMap.subscribe((params) => {
      const layout = params.get('layout');

      switch (layout) {
        case 'alpha': {
          this.store.dispatch(new SelectLayoutAction('alpha'));
          break
        }

        case 'beta': {
          this.store.dispatch(new SelectLayoutAction('beta'));
          break
        }

        case 'gamma': {
          this.store.dispatch(new SelectLayoutAction('gamma'));
          break
        }
      }

      const elevation = params.get('elevation');

      if (elevation) {
        this.store.dispatch(new SetCardElevationAction('card-elevation-z' + elevation))
      }
    });

    // Define Menu Items here

    // Top Level Item (The item to click on so the dropdown opens)
    const home = new SidenavItem({
      name: 'Home',
      //icon: 'home',
      route: '/',
      subItems: [ ],
      position: 1
    });

    const overons = new SidenavItem({
      name: 'Over ons',
      //icon: 'help',
      route: '/overons',
      subItems: [ ],
      position: 1
    });

    const repertoire = new SidenavItem({
      name: 'Repertoire',
  //icon: 'library_music',
      route: '/repertoire',
      subItems: [ ],
      position: 1
    });

    const beeldengeluid = new SidenavItem({
      name: 'Beeld en geluid',
//      icon: 'photo_library',
      route: '/beeldengeluid',
      subItems: [ ],
      position: 1
    });

    const agenda = new SidenavItem({
      name: 'Agenda',
//      icon: 'group',
      route: '/agenda',
      subItems: [ ],
      position: 1
    });

    const smoelenboek = new SidenavItem({
      name: 'Smoelenboek',
//      icon: 'group',
      route: '/smoelenboek',
      subItems: [ ],
      position: 1
    });

    const leden = new SidenavItem({
      name: 'Leden',
//      icon: 'group',
      route: '/leden',
      subItems: [ ],
      position: 1
    });

    // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(home));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(overons));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(repertoire));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(beeldengeluid));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(agenda));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(smoelenboek));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(leden));
  }

}
