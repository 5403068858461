import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AgmCoreModule } from '@agm/core';
import { RouteHandlerModule } from './core/route-handler/route-handler.module';
import { HttpClientModule } from '@angular/common/http';
import { DeliveryClientProvider } from "./setup/delivery-client.provider";
import { ContentPageService } from './pages/content-page/core/content-page.service';
import { ContentPageEffects } from './pages/content-page/core/content-page.effects';
import { AuthenticatedGuard } from './core/services/authenticated.guard';
import { AuthenticationService } from './pages/auth/core/auth.service';
import { CredentialsEffects } from './pages/auth/core/auth.effects';



@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'elastic-ui' }),
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    EffectsModule.forRoot([ContentPageEffects,CredentialsEffects]),
    AppRoutingModule,
    CoreModule,
    PagesModule,
    RouteHandlerModule,
  ],
  providers: [DeliveryClientProvider,ContentPageService,AuthenticatedGuard,AuthenticationService],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
