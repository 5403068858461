// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  projectId : 'bfd394eb-8468-00e8-42c8-652922a57ccf',
  baseUrl : 'https://webapi-v2-truelimedws-test.azurewebsites.net',
  //baseUrl : 'http://webapi',
  webApi : '/api/v1'
};
