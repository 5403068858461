import { Injectable } from "@angular/core";

// import @ngrx
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";

// import rxjs
import { Observable, of } from "rxjs";
import { catchError, switchMap, map, debounceTime } from 'rxjs/operators';

// import services
import { AuthenticationService } from "./auth.service";
import * as authenticationActions from './auth.actions';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 * The `toPayload` helper function returns just
 * the payload of the currently dispatched action, useful in
 * instances where the current state is not necessary.
 *
 * Documentation on `toPayload` can be found here:
 * https://github.com/ngrx/effects/blob/master/docs/api.md#topayload
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 * 
 * HOI, LEUK DAT JE DIT LEEST, toPayload is verwijderd uit ngrx, dus gebruik .pipe(map(action:ActionType) => action.payload) en plak daar je switchmap achter
 */

@Injectable()
export class CredentialsEffects {
    /**
     * @constructor
     * @param {Actions} actions$
     * @param {UserService} userService
     */
    constructor(
        private actions$: Actions,
        private authenticationService: AuthenticationService
    ) { }

    /**
     * Authenticate user.
     * @method authenticate
     */

    @Effect()
    public authenticate = this.actions$
        .pipe(
            ofType(authenticationActions.AUTHENTICATE))
        .pipe(
            debounceTime(500),
            map((action: authenticationActions.AuthenticateAction) => action.payload),
            switchMap(payload => {
                return this.authenticationService.authenticate(payload.email, payload.password).pipe(
                    map(result =>
                        new authenticationActions.AuthenticationSuccessAction(result)
                    ),
                    catchError(error => of(new authenticationActions.AuthenticationErrorAction({ error: error })))
                )
            })
        )



    @Effect()
    public signOut = this.actions$
        .pipe(  
        ofType(authenticationActions.SIGN_OUT))
        .pipe(
            map((action: authenticationActions.SignOutAction) => action.payload),
            switchMap(payload => {
                return this.authenticationService.signout().pipe(
                    map(value => new authenticationActions.SignOutSuccessAction()),
                    catchError(error => of(new authenticationActions.SignOutErrorAction({ error: error })))
                )
            })
        )
}
