import { ContentPage } from './content-page.model';
import * as fromContentPages from './content-page.actions';
import { RepertoireItem } from './repertoire-item.model';
import { YouTubeVideo } from './youtube-video.model';
import { GooglePhotoAlbum } from './google-photo-album.model';
import { AgendaItem } from './agenda-item.model';


export interface State {
    totalRecordOnServer : number,
    loaded: boolean,
    paginas: ContentPage[];
    repertoire: RepertoireItem[];
    videos: YouTubeVideo[];
    albums: GooglePhotoAlbum[];
    agenda: AgendaItem[]
};

const initialState: State = {
    totalRecordOnServer : -1,
    loaded: false,
    paginas: [],
    repertoire: [],
    videos: [],
    albums: [],
    agenda: []
};

export function contentPageReducer(state = initialState, action: fromContentPages.Actions): State {
    switch (action.type) {
        case fromContentPages.FORCE_RELOAD_CONTENT_PAGES:
        case fromContentPages.LOAD_CONTENT_PAGES: {
            return state = {
                ...state,
                loaded: false,
            };
        }
        case fromContentPages.LOAD_CONTENT_PAGES_SUCCESS:
        case fromContentPages.FORCE_RELOAD_CONTENT_PAGES_SUCCESS: {
            if (!action.payload) return state;
            return state = {
                ...state,
                loaded: true,
                paginas: action.payload
            };
        }
        case fromContentPages.LOAD_REPERTOIRE_SUCCESS: {
            if (!action.payload) return state;
            return state = {
                ...state,
                loaded: true,
                repertoire: action.payload
            };
        }
        case fromContentPages.LOAD_VIDEOS_SUCCESS: {
            if (!action.payload) return state;
            return state = {
                ...state,
                loaded: true,
                videos: action.payload
            };
        }
        case fromContentPages.LOAD_ALBUMS_SUCCESS: {
            if (!action.payload) return state;
            return state = {
                ...state,
                loaded: true,
                albums: action.payload
            };
        }
        case fromContentPages.LOAD_AGENDA_SUCCESS: {
            if (!action.payload) return state;
            return state = {
                ...state,
                loaded: true,
                agenda: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
