import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authRoutes } from './pages/auth/auth.routing';
import { AuthenticatedGuard } from './core/services/authenticated.guard';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      ...authRoutes
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        canActivate: [AuthenticatedGuard],
        path: 'leden',
        loadChildren: 'app/pages/content-page/content-page.module#ContentPageModule'
      },
      {
        path: '**',
        loadChildren: 'app/pages/content-page/content-page.module#ContentPageModule'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
