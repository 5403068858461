import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay, map, switchMap, catchError } from 'rxjs/operators';

import { ContentPage } from './content-page.model';
import { ContentPageList } from './content-pagelist.model';
import { BaseService } from './../../../core/services/base.service';
import * as layoutActions from './../../../core/layout/shared/layout.action';
import {
  ContentItem,
  ContentType,
  DeliveryClient,
  SortOrder,
  TaxonomyGroup,
} from 'kentico-cloud-delivery';
import { CloudError } from 'kentico-cloud-core';
import { RepertoireItem } from './repertoire-item.model';
import { YouTubeVideo } from './youtube-video.model';
import { GooglePhotoAlbum } from './google-photo-album.model';
import { AgendaItem } from './agenda-item.model';


@Injectable()
export class ContentPageService extends BaseService {

  loadContentPages(): Observable<ContentPage[]> {
    return this.deliveryClient.items<ContentPage>()
      .type("pagina")
      .getObservable().pipe(map(response => {
        return response.items
      }
      )
      )
  }

  loadRepertoire(): Observable<RepertoireItem[]> {
    return this.deliveryClient.items<RepertoireItem>()
      .type("repertoire_item")
      .getObservable().pipe(map(response => {
        return response.items
      }
      )
      )
  }

  loadAgenda(): Observable<AgendaItem[]> {
    return this.deliveryClient.items<AgendaItem>()
      .type("agenda_item")
      .getObservable().pipe(map(response => {
        return response.items
      }
      )
      )
  }

  loadVideos(): Observable<YouTubeVideo[]> {
    return this.deliveryClient.items<YouTubeVideo>()
      .type("youtube_video")
      .getObservable().pipe(map(response => {
        return response.items
      }
      )
      )
  }

  loadAlbums(): Observable<GooglePhotoAlbum[]> {
    return this.deliveryClient.items<GooglePhotoAlbum>()
      .type("google_photo_album")
      .getObservable().pipe(map(response => {
        return response.items
      }
      )
      )
  }

}

