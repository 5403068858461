import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { State } from './../../../reducers/index';
import { Store } from '@ngrx/store';
import { AuthenticateAction } from './../core/auth.actions';
import { takeWhile, filter } from 'rxjs/operators';

@Component({
  selector: 'elastic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  private alive = true;
  firstTry: boolean = true;

  constructor(
    private router: Router,
    private store: Store<State>,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const self = this;

    // subscribe to success
    this.store.select(state => state.authentication.authenticated).pipe(
      takeWhile(() => this.alive),
      filter(authenticated => authenticated)
    ).subscribe(result => {
      if (result)
        this.router.navigate(['leden']);
    });

    // autologin for speeding dev

    // set payload
    // const payload = {
    //   email: "tim",
    //   password: "truelime"
    // };

    // // // dispatch AuthenticationAction and pass in payload
    // this.store.dispatch(new AuthenticateAction(payload));

  }

  public login() {
    // trim values
    this.email.trim();
    this.password.trim();

    // set payload
    const payload = {
      email: this.email,
      password: this.password
    };

    // dispatch AuthenticationAction and pass in payload
    this.store.dispatch(new AuthenticateAction(payload));

    setTimeout(() => {
      this.firstTry = false;
    }, 2000);    
    
  }

}
