import * as authenticationActions from "./auth.actions";

export interface State {
    authenticated: boolean,
};

const initialState: State = {
    authenticated: false,
};

export function authReducer(state: any = initialState, action: authenticationActions.Actions): State {
    switch (action.type) {
        case authenticationActions.AUTHENTICATE_SUCCESS: {

            return state = {
                ...state,
                authenticated: action.payload,
                error: undefined,
                loading: false,
            };
        }
        default: {
            return state;
        }
    
    }
}
