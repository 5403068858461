import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationType } from 'angular2-notifications';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as layoutActions from './../../../core/layout/shared/layout.action';
import { ToastMessage } from './../../../core/models/toastmessage';
import { State } from './../../../reducers';
import * as contentPageActions from './content-page.actions';
import { ContentPageService } from './content-page.service';
import * as fromContentPages from './content-page.reducer';


@Injectable()
export class ContentPageEffects {

    constructor(
        private store: Store<State>,
        private actions$: Actions,
        private contentPageService: ContentPageService
    ) { }

    @Effect() loadContentPages$ = this.actions$
        .pipe(
            ofType(contentPageActions.LOAD_CONTENT_PAGES),
            withLatestFrom(
                this.store.select(state => state.content)
            ),
            switchMap(([action, state]: [contentPageActions.LoadContentPagesAction, fromContentPages.State]): any => {

                //if ((state.totalRecordOnServer === -1) || (state.data.length < state.totalRecordOnServer)) {
                return this.contentPageService.loadContentPages().pipe(
                    map(paginas => new contentPageActions.LoadContentPagesSuccessAction(paginas)),
                    catchError(() => {
                        this.store.dispatch(new layoutActions.ShowMessageAction(new ToastMessage("ERROR", "error loading pages", NotificationType.Error)));
                        return of(new contentPageActions.ErrorForContentPagesAction())
                    }))
                //}
                //else
                //  return of(new searchResultActions.LoadedFromStateSuccessAction());
            })
        )

    @Effect() loadRepertoire$ = this.actions$
        .pipe(
            ofType(contentPageActions.LOAD_REPERTOIRE),
            withLatestFrom(
                this.store.select(state => state.content)
            ),
            switchMap(([action, state]: [contentPageActions.LoadContentPagesAction, fromContentPages.State]): any => {

                //if ((state.totalRecordOnServer === -1) || (state.data.length < state.totalRecordOnServer)) {
                return this.contentPageService.loadRepertoire().pipe(
                    map(repertoire => new contentPageActions.LoadRepertoireSuccessAction(repertoire)),
                    catchError(() => {
                        this.store.dispatch(new layoutActions.ShowMessageAction(new ToastMessage("ERROR", "error loading repertoire", NotificationType.Error)));
                        return of(new contentPageActions.ErrorForContentPagesAction())
                    }))
                //}
                //else
                //  return of(new searchResultActions.LoadedFromStateSuccessAction());
            })
        )


    @Effect() loadVideos$ = this.actions$
        .pipe(
            ofType(contentPageActions.LOAD_VIDEOS),
            withLatestFrom(
                this.store.select(state => state.content)
            ),
            switchMap(([action, state]: [contentPageActions.LoadContentPagesAction, fromContentPages.State]): any => {

                //if ((state.totalRecordOnServer === -1) || (state.data.length < state.totalRecordOnServer)) {
                return this.contentPageService.loadVideos().pipe(
                    map(videos => new contentPageActions.LoadVideosSuccessAction(videos)),
                    catchError(() => {
                        this.store.dispatch(new layoutActions.ShowMessageAction(new ToastMessage("ERROR", "error loading videos", NotificationType.Error)));
                        return of(new contentPageActions.ErrorForContentPagesAction())
                    }))
                //}
                //else
                //  return of(new searchResultActions.LoadedFromStateSuccessAction());
            })
        )


    @Effect() loadAlbums$ = this.actions$
        .pipe(
            ofType(contentPageActions.LOAD_ALBUMS),
            withLatestFrom(
                this.store.select(state => state.content)
            ),
            switchMap(([action, state]: [contentPageActions.LoadContentPagesAction, fromContentPages.State]): any => {

                //if ((state.totalRecordOnServer === -1) || (state.data.length < state.totalRecordOnServer)) {
                return this.contentPageService.loadAlbums().pipe(
                    map(albums => new contentPageActions.LoadAlbumsSuccessAction(albums)),
                    catchError(() => {
                        this.store.dispatch(new layoutActions.ShowMessageAction(new ToastMessage("ERROR", "error loading albums", NotificationType.Error)));
                        return of(new contentPageActions.ErrorForContentPagesAction())
                    }))
                //}
                //else
                //  return of(new searchResultActions.LoadedFromStateSuccessAction());
            })
        )

        @Effect() loadAgenda$ = this.actions$
        .pipe(
            ofType(contentPageActions.LOAD_AGENDA),
            withLatestFrom(
                this.store.select(state => state.content)
            ),
            switchMap(([action, state]: [contentPageActions.LoadContentPagesAction, fromContentPages.State]): any => {

                //if ((state.totalRecordOnServer === -1) || (state.data.length < state.totalRecordOnServer)) {
                return this.contentPageService.loadAgenda().pipe(
                    map(agenda => new contentPageActions.LoadAgendaSuccessAction(agenda)),
                    catchError(() => {
                        this.store.dispatch(new layoutActions.ShowMessageAction(new ToastMessage("ERROR", "error loading agenda", NotificationType.Error)));
                        return of(new contentPageActions.ErrorForContentPagesAction())
                    }))
                //}
                //else
                //  return of(new searchResultActions.LoadedFromStateSuccessAction());
            })
        )



};

