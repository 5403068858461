import { Action } from '@ngrx/store';
import { ContentPage } from './content-page.model';
import { RepertoireItem } from './repertoire-item.model';
import { YouTubeVideo } from './youtube-video.model';
import { GoogleMapsScriptProtocol } from '@agm/core';
import { GooglePhotoAlbum } from './google-photo-album.model';
import { AgendaItem } from './agenda-item.model';

export const LOAD_CONTENT_PAGES = 'LOAD_CONTENT_PAGES';
export const LOAD_CONTENT_PAGES_SUCCESS = 'LOAD_CONTENT_PAGES_SUCCESS';
export const LOAD_REPERTOIRE = 'LOAD_REPERTOIRE';
export const LOAD_REPERTOIRE_SUCCESS = 'LOAD_REPERTOIRE_SUCCESS';
export const LOAD_AGENDA = 'LOAD_AGENDA';
export const LOAD_AGENDA_SUCCESS = 'LOAD_AGENDA_SUCCESS';
export const LOAD_VIDEOS = 'LOAD_VIDEOS';
export const LOAD_VIDEOS_SUCCESS = 'LOAD_VIDEOS_SUCCESS';
export const LOAD_ALBUMS = 'LOAD_ALBUMS';
export const LOAD_ALBUMS_SUCCESS = 'LOAD_ALBUMS_SUCCESS';
export const LOADED_FROM_STATE_SUCCESS = 'LOADED_FROM_STATE_SUCCESS';
export const FORCE_RELOAD_CONTENT_PAGES = 'FORCE_RELOAD_CONTENT_PAGES';
export const FORCE_RELOAD_CONTENT_PAGES_SUCCESS = 'FORCE_RELOAD_CONTENT_PAGES_SUCCESS';
export const ERROR_FOR_CONTENT_PAGES = 'ERROR_FOR_CONTENT_PAGES';

export class LoadContentPagesAction implements Action {
    readonly type = LOAD_CONTENT_PAGES

    constructor() { }
}

export class LoadContentPagesSuccessAction implements Action {
    readonly type = LOAD_CONTENT_PAGES_SUCCESS;

    constructor(public payload: ContentPage[]) { }
}

export class LoadRepertoireAction implements Action {
    readonly type = LOAD_REPERTOIRE

    constructor() { }
}

export class LoadRepertoireSuccessAction implements Action {
    readonly type = LOAD_REPERTOIRE_SUCCESS

    constructor(public payload: RepertoireItem[]) { }
}

export class LoadAgendaAction implements Action {
    readonly type = LOAD_AGENDA

    constructor() { }
}

export class LoadAgendaSuccessAction implements Action {
    readonly type = LOAD_AGENDA_SUCCESS

    constructor(public payload: AgendaItem[]) { }
}

export class LoadVideosAction implements Action {
    readonly type = LOAD_VIDEOS

    constructor() { }
}

export class LoadVideosSuccessAction implements Action {
    readonly type = LOAD_VIDEOS_SUCCESS

    constructor(public payload: YouTubeVideo[]) { }
}

export class LoadAlbumsAction implements Action {
    readonly type = LOAD_ALBUMS

    constructor() { }
}

export class LoadAlbumsSuccessAction implements Action {
    readonly type = LOAD_ALBUMS_SUCCESS

    constructor(public payload: GooglePhotoAlbum[]) { }
}

export class LoadedFromStateSuccessAction implements Action {
    readonly type = LOADED_FROM_STATE_SUCCESS;

    constructor() { }
}

export class ForceReloadContentPagesAction implements Action {
    readonly type = FORCE_RELOAD_CONTENT_PAGES

    constructor() { }
}

export class ForceReloadContentPagesSuccessAction implements Action {
    readonly type = FORCE_RELOAD_CONTENT_PAGES_SUCCESS

    constructor(public payload: ContentPage[]) { }
}

export class ErrorForContentPagesAction implements Action {
    readonly type = ERROR_FOR_CONTENT_PAGES

    constructor() { }
}


export type Actions
    = LoadContentPagesAction
    | LoadContentPagesSuccessAction
    | LoadRepertoireAction
    | LoadRepertoireSuccessAction
    | LoadAgendaAction
    | LoadAgendaSuccessAction
    | LoadAlbumsAction
    | LoadAlbumsSuccessAction
    | LoadVideosAction
    | LoadVideosSuccessAction
    | LoadedFromStateSuccessAction
    | ForceReloadContentPagesAction
    | ForceReloadContentPagesSuccessAction
    | ErrorForContentPagesAction

