// kentico cloud
import { DeliveryClient, TypeResolver } from 'kentico-cloud-delivery';

// models
// import { Actor } from '../models/actor.class';
// import { Movie } from '../models/movie.class';
import { ContentPage} from './../pages/content-page/core/content-page.model';

export function DeliveryClientFactory() {

    const projectId = 'bfd394eb-8468-00e8-42c8-652922a57ccf';

    const typeResolvers: TypeResolver[] = [
    //   new TypeResolver('movie', () => new Movie()),
    //   new TypeResolver('actor', () => new Actor()),
      new TypeResolver('pagina', () => new ContentPage())
    ];

    return new DeliveryClient(
        {
          projectId: projectId,
          typeResolvers: typeResolvers
        }
    );
}

export const DeliveryClientProvider = {
    provide: DeliveryClient,
    useFactory: DeliveryClientFactory,
    deps: []
};
