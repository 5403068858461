import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { filter } from 'rxjs/operators';
import { State } from './../../reducers/index';
import { environment } from './../../../environments/environment';
import {
    ContentItem,
    ContentType,
    DeliveryClient,
    SortOrder,
    TaxonomyGroup,
  } from 'kentico-cloud-delivery';
  import { CloudError} from 'kentico-cloud-core';

@Injectable()
export abstract class BaseService {
    API_BASE = environment.baseUrl + environment.webApi;

    constructor(protected http: HttpClient, protected store: Store<State>, protected sanitizer: DomSanitizer, protected deliveryClient: DeliveryClient) {
        // this.store.select(state => state.authentication.tokens).pipe(
        //     filter(x => x != undefined),
        //     filter(data => data.length > 0)
        // )
        // .subscribe(tokens => {
        //     this.dwsTokens = [];
        //     this.dwsTokens = tokens;
        // });
    }

    protected provideHeaders(addJsonHeader = true) {
        // let repoToken = this.dwsTokens.find(token => token.key === "nodeRepositoryToken");
        // if(!repoToken) console.warn("repository token undefined");
        // let defaultToken = this.dwsTokens.find(token => token.key === "defaultToken");
        // if(!defaultToken) console.warn("default token undefined");

        let headers = new HttpHeaders()
//            .set('Authorization', 'Bearer ' + defaultToken.token)
            .set('Content-Type', 'application/json');

        // if (addJsonHeader)
        //     headers.append('Content-Type', 'application/json');


        return headers;

    }

    protected errorHandler(error: Response | any) {
        console.log(error.message);
        return throwError(error);
      }
}