import { ContentItem, Fields } from 'kentico-cloud-delivery';

export class ContentPage extends ContentItem {
  public headerFoto: Fields.AssetsField;
  public hoofdTitel: Fields.TextField;
  public subTitel: Fields.TextField;
  public inhoud: Fields.TextField;
  public youtube_items: ContentItem[];

  constructor() {
    super({
      propertyResolver: (fieldName) => {
        if (fieldName === 'header_foto') {
          return 'headerFoto'; // binds 'first_name' response from Kentico cloud to 'firstName' property of this class
        }
        if (fieldName === 'hoofdtitel') {
          return 'hoofdTitel';
        }
        if (fieldName === 'subtitel') {
          return 'subTitel';
        }
        if (fieldName === 'inhoud') {
          return 'inhoud';
        }
        if (fieldName === 'youtube_items') {
          return 'youtube_items';
        }
      }
    });
  }

  public getHeaderFoto(): string {
    return `${this.headerFoto.value[0].url}`;
  }

  public getHoofdTitel(): string {
    return `${this.hoofdTitel.text}`;
  }

  public getSubTitel(): string {
    return `${this.subTitel.text}`;
  }

  public getInhoud(): string {
    return `${this.inhoud.value}`;
  }

  public getYouTubeItems(): any {
    return `${this.youtube_items}`;
  }

}
